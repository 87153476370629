
.requestsHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.requestsRange{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.reauestsProgress{
    display: flex;
}

@media only screen and (max-width: 600px) {

    :where(.css-dev-only-do-not-override-1rqnfsa).ant-col{
        font-family: 'Tajawal';
    }
    .requestsHeader{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .requestsRange{
        order:1;
        align-items: end;
        flex-direction: column;
        align-items: flex-start;
    }
    .reauestsProgress{
        order: 2;
        flex-direction: column;
        align-items: center;
    }
    .requestsBtn{
        width: 100%;
    }
}