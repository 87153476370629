table{
    text-align: center!important;
}
.ant-table-column-sorters-with-tooltip{
    text-align: center;
}
td{
    width: auto!important;
}
.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap');


    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .discountHeader{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .discountBtn{
    display: flex;
    flex-direction: row;
    }
    @media only screen and (max-width: 600px) {
    
        .discountHeader{
            flex-direction: column;
        }
        .discountRange{
            order: 2;
        }
        .discountBtn{
            justify-content: flex-end;
            margin-bottom: 10px;
            }
    }

    td, th {
        page-break-inside: avoid !important;
    }

    @media print {
        td, th,tr {page-break-inside: avoid;}
      }